import React, { useEffect } from 'react';

const emojis = ['💀', '☠️', '🏴‍☠️', '🪦','⚰️']; 

const fallingEmojiStyle = (size, left, duration) => ({
  position: 'absolute',
  fontSize: `${size}px`,
  left: `${left}%`,
  animation: `fall ${duration}s linear infinite`,
});

const Background = () => {
  useEffect(() => {
    const emojiContainer = document.getElementById('emoji-container');

    for (let i = 0; i < 50; i++) { 
      const emoji = document.createElement('div');
      const size = Math.random() * 20 + 10; 
      const left = Math.random() * 100; 
      const duration = Math.random() * 5 + 5; 

      emoji.innerText = emojis[Math.floor(Math.random() * emojis.length)];
      Object.assign(emoji.style, fallingEmojiStyle(size, left, duration));

      emojiContainer.appendChild(emoji);
    }
  }, []);

  return (
    <div id="emoji-container" style={{
      position: 'fixed', 
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: 'linear-gradient(#000, #121212)', 
      zIndex: -1, 
    }}>
    </div>
  );;
};

export default Background;
