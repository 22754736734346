import React from 'react';
import './index.css'; // Ensures Tailwind CSS is applied
import Background from './Background';

function App() {
  return (
    <div className="relative min-h-screen flex flex-col">
      <Background />
      <div className="flex justify-between p-4 z-10">
        <div className="flex space-x-2">
          <a
            href="app.devdie.dev" // Adjust the href to your DAPP's link
            className="bg-white hover:bg-gray-200 text-black font-bold py-1 px-3 rounded transition duration-300 ease-in-out border border-gray-400"
            style={{ fontFamily: '"Roboto Mono"' }}
          >
            DAPP
          </a>
        </div>
        <div className="flex space-x-2">
          <a
            href="mailto:dev@devdie.dev"
            className="bg-white hover:bg-gray-200 text-black font-bold py-1 px-3 rounded transition duration-300 ease-in-out border border-gray-400"
            style={{ fontFamily: '"Roboto Mono"' }}
          >
            eMail
          </a>
        </div>
      </div>

      <div className="flex-grow flex items-center justify-center text-center">
        <div className="w-full px-4 md:w-2/3 lg:w-1/2 mx-auto">
          <h1 className="text-4xl font-bold mb-4" style={{ fontFamily: '"Press Start 2P", cursive' }}>
            devdie.dev
          </h1>
          <p className="text-xl mb-8 font-robot-mono">
            We write experimental, modern smart contracts that improve both sides: the dev & user experience, designed for devs seeking a peaceful exit and for degens desiring the end of conventional snipe challenges.

            Utilize DevDieBot to disperse funds with a single click, clean up your sniper wallets funds & tokens, deploy contracts, audits and more. And Remember "
             <span className="font-bold">die dev die!</span>
          </p>
          <div className="flex space-x-2 justify-center">
            <a
              href="https://t.me/devdiedev"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out border border-gray-400"
              style={{ fontFamily: '"Roboto Mono"' }}
            >
              Telegram
            </a>
            <a
              href="https://docs.devdie.dev"
              className="inline-block bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out border border-gray-400"
              style={{ fontFamily: '"Roboto Mono"' }}
              target="_blank" rel="noopener noreferrer"
            >
              DOCS
            </a>
            <a
              href="https://t.me/devdiebot" // Adjust the href to your DIEDEVBOT's launch link
              className="inline-block bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded transition duration-300 ease-in-out border border-gray-400"
              style={{ fontFamily: '"Roboto Mono"' }}
            >
              LAUNCH DIEDEVBOT
            </a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
